import React from "react";

const Picture = ({defaultSource, mobileSource}) => {
  return (
    <picture>
      <source srcSet={defaultSource} media="(min-width: 576px)" />
      <source srcSet={mobileSource} media="(max-width: 575px)" />
      <img src={defaultSource} alt="Standaard grafiek" />
    </picture>
  );
};

export default Picture;
