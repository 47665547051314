import React from "react";
import Breadcrumbs from "./Breadcrumbs";

const Page = ({ route }) => {
  const PageBody = route.component;
  return (
    <>
      <div id="navBar">
        <div className="wrapper">
          <nav className="breadCrumbNav">
            <span className="assistive">U bevindt zich hier:</span>
            {<Breadcrumbs route={route} />}
          </nav>
        </div>
      </div>
      <main>
        <div className={route.container ? route.container : "container"}>
          <div className="content-wrapper" id="content-wrapper">
            <PageBody />
          </div>
        </div>
      </main>
    </>
  );
};

export default Page;
