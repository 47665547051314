import React, { Component } from 'react';

class PageVlekkenkaart extends Component {
  render() {
    return <div className="page-vlekkenkaart">
      <div className="map">
        <iframe title="vlekkenkaart" src="https://ez.maps.arcgis.com/apps/webappviewer/index.html?id=09b0320dfb264238ac2874986d0b77f8"></iframe>
      </div>
    </div>
    ;
  }
}

export default PageVlekkenkaart;
