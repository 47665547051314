import React, { Component } from 'react';
import ContentHeader from '../partials/content-header/ContentHeader';
import TableVlekken1 from '../Tables/TableVlekken1';
import TableVlekken2 from '../Tables/TableVlekken2';
import TableVlekken3 from '../Tables/TableVlekken3';
import TableVlekken4 from '../Tables/TableVlekken4';
import TableVlekken5 from '../Tables/TableVlekken5';
import TableVlekken6 from '../Tables/TableVlekken6';
import TableVlekken7 from '../Tables/TableVlekken7';
import TableVlekken8 from '../Tables/TableVlekken8';
import TableVlekken9 from '../Tables/TableVlekken9';
import TableVlekken10 from '../Tables/TableVlekken10';
import TableVlekken11 from '../Tables/TableVlekken11';
import TableVlekken12 from '../Tables/TableVlekken12';

class PageVlekkentabel extends Component {
  render() {
    return <div className="page-vlekkentabel">
      <ContentHeader>
        <h1>Vlekkentabel</h1>
        <p>Om voor een gebouw dat in typologie Staal-A valt, de vlekkentabellen te kunnen toepassen, dient een aantal stappen te worden doorlopen. Deze zijn hieronder weergegeven.</p>
      </ContentHeader>

      <div className="pagebreak"></div>

      <div className="content__block__vlekken">
        <h2>Stap 1 Bepaal of er sprake is van een bebouwde of onbebouwde omgeving</h2>
        <p>
          Stel vast of het te beoordelen gebouw zich bevindt in een bebouwde of in een onbebouwde omgeving.
          Er is sprake van een bebouwde omgeving als in een straal van 500 meter rondom het gebouw 10% of meer van de oppervlakte bebouwd is met gebouwen met een hoogte van 7 meter of meer.
          Wanneer dit niet het geval is, dan spreekt men van een onbebouwde omgeving.
        </p>
        <p>
          Als er sprake is van een bebouwde omgeving, ga verder met stap 1A.<br/>
          Als er sprake is van een onbebouwde omgeving, ga verder met stap 2.
        </p>
      </div>

      <div className="content__block__vlekken">
        <h3 className="paragraph-title">Stap 1A Pas de uitkomst “bebouwde omgeving” zo nodig aan de hand van ontwerpberekeningen aan</h3>
        <p>Kijk of er constructieve ontwerpberekeningen beschikbaar zijn voor het gebouw.</p>
        <p>
          Als uit de constructieve ontwerpberekeningen blijkt dat er in de ontwerpberekeningen rekening is gehouden met een onbebouwde omgeving, dan wordt van onbebouwde omgeving uitgegaan.
          Mochten er geen constructieve ontwerpberekeningen beschikbaar zijn, dan wordt de uitkomst bebouwde omgeving uit stap 1, niet bijgesteld. Ga verder met stap 2.
        </p>
      </div>

      <div className="content__block__vlekken">
        <h2>Stap 2 Bepaal de afmetingen van het gebouw</h2>
        <p>
          Stel eerst de maximale breedte van het gebouw vast om te bepalen welke vlekkentabel gehanteerd moet worden.
          Bepaal vervolgens de maximale lengte en maximale hoogte van het gebouw.
        </p>
      </div>

      <div className="content__block__vlekken">
        <h2>Stap 3 Bepaal welke vlekkentabel van toepassing is</h2>
        <p>Om te bepalen welke vlekkentabel van toepassing is, wordt gekeken of het gebouw in een bebouwde (stap 3A) of in een onbebouwde omgeving (stap 3B) ligt.</p>

        <h3 className="paragraph-title">Stap 3A Bebouwde omgeving</h3>
        <p>Als sprake is van een gebouw in een bebouwde omgeving, dan is tabel 1, 2, 3 of 4 van toepassing. Bepaal met de vastgestelde breedte van het gebouw welke van deze vlekkentabellen moet worden gebruikt:</p>
        <ul className="list__dash-list">
          <li className="list__dash-list-item">tabel 1 ziet op een gebouw met een breedte van 10 meter</li>
          <li className="list__dash-list-item">tabel 2 ziet op een gebouw met een breedte van 20 meter</li>
          <li className="list__dash-list-item">tabel 3 ziet op een gebouw met een breedte van 30 meter</li>
          <li className="list__dash-list-item">tabel 4 ziet op een gebouw met een breedte van 40 meter</li>
        </ul>
        <p>
          Ligt de breedte van een gebouw tussen de genoemde breedtes van 10, 20, 30 en 40 meter in (een gebouw is bijvoorbeeld 15 meter breed), dan moet tussen de twee vlekkentabellen moet worden geïnterpoleerd.
          Dit komt aan de orde in stap 4.
        </p>

        <h3 className="paragraph-title">Stap 3B Onbebouwde omgeving</h3>
        <p>Als sprake is van een gebouw in een onbebouwde omgeving, dan is tabel 5, 6, 7, 8 of 9 van toepassing. Bepaal met de vastgestelde breedte van het gebouw welke van deze vlekkentabellen moet worden gebruikt:</p>
        <ul className="list__dash-list">
          <li className="list__dash-list-item">tabel 5 ziet op een gebouw met een breedte van 10 meter</li>
          <li className="list__dash-list-item">tabel 6 ziet op een gebouw met een breedte van 20 meter</li>
          <li className="list__dash-list-item">tabel 7 ziet op een gebouw met een breedte van 30 meter</li>
          <li className="list__dash-list-item">tabel 8 ziet op een gebouw met een breedte van 40 meter</li>
        </ul>

        <div className="card__body">
          <h3 className="card__title">Optioneel: gebouw ontworpen met TGB 1972 of later</h3>
          <p>De tabellen 5 tot en met 9 zijn van toepassing op gebouwen in de ongebouwde omgeving, ongeacht met welke windbelastingsnormen het gebouw is ontworpen.</p>
          <p>
            Maar: wanneer een gebouw is ontworpen met de windbelastingsnorm Technische Grondslagen voor de berekening van bouwconstructies - TGB 1972 (hierna: TGB 1972) of een opvolger daarvan, dan mag tabel 9, 10, 11 of 12 worden gehanteerd.
            Deze tabellen zijn minder conservatief dan de tabellen 5 tot en met 9.
          </p>
          <p>Als bekend is dat het gebouw is ontworpen met de TGB 1972 of een opvolger daarvan, dan kan aan de hand van de vastgestelde breedte van de bedrijfshal te tabel worden bepaald:</p>
          <ul className="list__dash-list">
            <li className="list__dash-list-item">tabel 9 ziet op een bedrijfshal met een breedte van 10 meter</li>
            <li className="list__dash-list-item">tabel 10 ziet op een bedrijfshal met een breedte van 20 meter</li>
            <li className="list__dash-list-item">tabel 11 ziet op een bedrijfshal met een breedte van 30 meter</li>
            <li className="list__dash-list-item">tabel 12 ziet op een bedrijfshal met een breedte van 40 meter</li>
          </ul>
        </div>

        <p>
          Ligt de breedte van een gebouw tussen de genoemde breedtes van 10, 20, 30 en 40 meter in (een gebouw is bijvoorbeeld 15 meter breed), dan moet tussen de twee vlekkentabellen worden geïnterpoleerd.
          Dit komt aan de orde in stap 4.
        </p>

        <div className="card__body">
          <p>
            De vlekkentabellen zien op gebouwen met een breedte van minimaal 10 meter en maximaal 40 meter, een lengte van minimaal 10 meter en maximaal 160 meter en een hoogte van minimaal 4 en maximaal 10 meter.
            Bij gebouwen die te smal of te breed, te laag of te hoog of te kort of te lang zijn om onder deze typologie te vallen, mogen de tabellen niet worden gebruikt door de waarden daarin te extrapoleren.
            Voor die gebouwen geldt dat ze niet onder deze typologie vallen en individueel met de NPR 9998 moeten worden beoordeeld. Extrapolatie van de waarden in de vlekkentabellen is voor deze gebouwen dus niet toegestaan.
          </p>
        </div>

        <p>Ga verder met stap 4.</p>
      </div>

      <div className="content__block__vlekken">
        <h2>Stap 4 Lees de vlekkentabel af voor het gebouw</h2>
        <p>Zoek de vlekkentabel op die van toepassing op het gebouw aan de hand van de vastgestelde breedte van het gebouw, of het gebouw in een bebouwde of onbebouwde omgeving ligt en eventueel of TGB 1972 of een latere windbelastingsnorm is toegepast bij het ontwerp.</p>
        <p>Kijk in de van toepassing zijnde tabel aan de hand van de vastgestelde hoogte en de vastgestelde lengte van het gebouw welke cel van de tabel van toepassing is.</p>

        <div className="card__body">
          <p>De cellen bevatten getallen. Het getal in de cel geeft de seismische weerstand aan die het gebouw ten minste moet hebben, vergeleken met de aardbevingsbelasting op dat gebouw, wil het gebouw voldoen aan de veiligheidsnorm.</p>
        </div>

        <p>Is de cel wit, dan voldoet het gebouw aan de veiligheidsnorm, ongeacht het getal in de witte cel. De seismische weerstand is vergeleken met de aardbevingsbelasting hoog genoeg. De aardbevingsbelasting hoeft niet te worden berekend. Er zijn geen maatregelen nodig.</p>
        <ul className="list__bullet-list">
          <li className="list__bullet-list-item">
            Is de cel paars, dan moet worden bekeken of het gebouw aan de veiligheidsnorm voldoet.
            Dit wordt gedaan door bekijken of het in de paarse cel opgenomen getal (de waarde van de seismische weerstand van het gebouw) gelijk is aan of hoger is dan de berekende aardbevingsbelasting op het gebouw; zo ja, dan voldoet het gebouw aan de veiligheidsnorm.
          </li>
          <li className="list__bullet-list-item">
            Is het in de paarse cel opgenomen getal (de waarde van de seismische weerstand van het gebouw) lager dan de berekende aardbevingsbelasting op dat gebouw, dan voldoet het gebouw niet aan de veiligheidsnorm.
            Dan moeten alle soorten maatregelen in ogenschouw worden genomen om het gebouw alsnog te laten voldoen aan de veiligheidsnorm.
          </li>
        </ul>
        <p>
          Als sprake is van een witte cel, kan het voorkomen dat een gebouw aan de veiligheidsnorm voldoet maar dat desondanks het zogenoemde “uit-het-vlak”-bezwijken van gemetselde, niet-dragende binnenwanden een risico vormt. In dit geval worden voor deze elementen separate uit-het-vlak maatregelen getroffen.
          Globaal blijft het tot typologie Staal-A behorende gebouw wel aan de veiligheidsnorm voldoen.
        </p>
        <p>
          Zoals bij stap 3 is aangegeven, wordt voor de betreffende waarden geïnterpoleerd <i>tussen</i> de tabellen als de breedte van een gebouw <i>tussen</i> de genoemde breedtes van 10, 20, 30 en 40 meter in ligt.
          Zo wordt bij een hal van 15 meter breed in een bebouwde omgeving geïnterpoleerd tussen de tabellen 1 en 2 voor de betreffende waarden. Hierbij is de kleur van de cellen niet relevant; het gaat alleen om de waarden in de cellen (de getallen).
        </p>
        <p>Als de geïnterpoleerde waarde groter is dan of gelijk is aan 0,50, dan voldoet het gebouw aan de veiligheidsnorm.</p>

        <div className="card__body">
          <p>Als de geïnterpoleerde waarde kleiner is dan 0,50, dan moet de waarde worden vergeleken met de te berekenen aardbevingsbelasting op dat gebouw.</p>
          <h3 className="card__title">Berekening van de aardbevingsbelasting</h3>
          <p>
            Als sprake is van een getal in een paarse cel, dan wordt zoals gezegd dat getal (de waarde van de seismische weerstand van het gebouw) vergeleken met de te berekenen aardbevingsbelasting op dat gebouw.
            De aardbevingsbelasting op het gebouw wordt berekend aan de hand van de locatie van het gebouw en de NPR:9998. De berekening gaat volgens de formule:
          </p>
          <ul className="list__unstyled-list">
            <li className="list__unstyled-list-item"><i>p * a<sub>g;s *</sub> 1,1</i></li>
            <li className="list__unstyled-list-item">waarbij <i>p</i> en <i>a<sub>g;s</sub></i> achtereenvolgens staan voor:</li>
            <li className="list__unstyled-list-item"><i>p</i> : de verhouding tussen de piekgrondversnelling en de plateauwaarde van de aardbevingsbelasting</li>
            <li className="list__unstyled-list-item"><i>a<sub>g;s</sub></i> : de rekenwaarde van de piekgrondversnelling</li>
          </ul>
          <p>
            De waarden van <i>p</i> en <i>a<sub>g;s</sub></i> voor de locatie van het gebouw zijn af te lezen uit de NPR-webtool, die behoort bij de NPR 9998. De NPR-webtool is te raadplegen op de <a href="http://seismischekrachten.nen.nl">seismischekrachten.nen.nl</a>.
            Bij het invullen van de gegevens in de NPR-webtool moet worden uitgegaan van tijdvak 5 en een herhalingstijd van 2.475 jaar. Daarnaast moet een horizontale richting worden gehanteerd.
          </p>
        </div>
      </div>

      <div className="pagebreak"></div>

      <div className="content__block__vlekken">
        <h2>Onderdeel 5b. Vlekkentabellen</h2>

        <div className="content__block__vlekken">
          <h3 className="paragraph-title">Overzicht vlekkentabellen</h3>
        </div>

        <div className="table__vlekken">
          <table className="table__vlekken__overview">
            <thead>
              <tr>
                <th>Breedte gebouw in meters</th>
                <th>Bebouwde omgeving</th>
                <th>Onbebouwde omgeving</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>10</td>
                <td>tabel 1</td>
                <td>tabel 5 (optioneel tabel 9, bij TGB 1972 of later)</td>
              </tr>
              <tr>
                <td>20</td>
                <td>tabel 2</td>
                <td>tabel 6 (optioneel tabel 10, bij TGB 1972 of later)</td>
              </tr>
              <tr>
                <td>30</td>
                <td>tabel 3</td>
                <td>tabel 7 (optioneel tabel 11, bij TGB 1972 of later)</td>
              </tr>
              <tr>
                <td>40</td>
                <td>tabel 4</td>
                <td>tabel 8 (optioneel tabel 12, bij TGB 1972 of later)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3 className="paragraph-title">Vlekkentabellen 1 t/m 4 - bebouwde omgeving</h3>
      </div>

      <div className="content__block__vlekken">
        <h4>Tabel 1 Gebouw breedte van 10 meter, ligt in bebouwde omgeving</h4>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken1></TableVlekken1>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 2 Gebouw breedte van 20 meter, ligt in bebouwde omgeving</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken2></TableVlekken2>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 3 Gebouw breedte van 30 meter, ligt in bebouwde omgeving</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken3></TableVlekken3>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 4 Gebouw breedte van 40 meter, ligt in bebouwde omgeving</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken4></TableVlekken4>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3 className="paragraph-title">Vlekkentabellen 5 t/m 8 - onbebouwde omgeving</h3>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 5 Gebouw breedte van 10 meter, ligt in onbebouwde omgeving </h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken5></TableVlekken5>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 6 Gebouw breedte van 20 meter, ligt in onbebouwde omgeving</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken6></TableVlekken6>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 7 Gebouw breedte van 30 meter, ligt in onbebouwde omgeving</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken7></TableVlekken7>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 8 Gebouw breedte van 40 meter, ligt in onbebouwde omgeving</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
        <TableVlekken8></TableVlekken8>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h4 className="paragraph-title">Vlekkentabellen 9 t/m 12 - onbebouwde omgeving, optioneel voor een gebouw ontworpen met TGB 1972 of later</h4>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 9 Gebouw breedte van 10 meter, ligt in onbebouwde omgeving, optioneel voor gebouw ontworpen met TGB 1972 of later</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken9></TableVlekken9>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 10 Gebouw breedte van 20 meter, ligt in onbebouwde omgeving, optioneel voor gebouw ontworpen met TGB 1972 of later</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken10></TableVlekken10>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 11 Gebouw breedte van 30 meter, ligt in onbebouwde omgeving, optioneel voor gebouw ontworpen met TGB 1972 of later</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken11></TableVlekken11>
        </div>
      </div>

      <div className="content__block__vlekken">
        <h3>Tabel 12 Gebouw breedte van 40 meter, ligt in onbebouwde omgeving, optioneel voor gebouw ontworpen met TGB 1972 of later</h3>
        <p className="table__vlekken__intro">
          Witte cel: betreffend gebouw voldoet aan de veiligheidsnorm<br/>
          Paarse cel: betreffend gebouw voldoet alleen aan veiligheidsnorm als de in de cel opgenomen waarde van de seismische weerstand gelijk is aan of hoger is dan de te berekenen aardbevingsbelasting op dat gebouw.
        </p>
        <div className="table__vlekken">
          <TableVlekken12></TableVlekken12>
        </div>
      </div>
    </div>
    ;
  }
}

export default PageVlekkentabel;
