import React, { Component } from 'react';

class TableVlekken7 extends Component {
  render() {
    return (
      <table>
        <thead>
          <tr>
            <td></td>
            <th className='first-row' colSpan={6}>Lengte gebouw in meters</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>Hoogte gebouw in meters</th>
            <th>= 30</th>
            <th>{'>'} 30 en ≤ 45</th>
            <th>{'>'} 45 en ≤ 60</th>
            <th>{'>'} 60 en ≤ 75</th>
            <th>{'>'} 75 en ≤ 90</th>
            <th>{'>'} 90 en ≤ 120</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>≥ 4 en {'<'} 5</th>
            <td className='colored-cell'>0,24</td>
            <td className='colored-cell'>0,18</td>
            <td className='colored-cell'>0,14</td>
            <td className='colored-cell'>0,11</td>
            <td className='colored-cell'>0,09</td>
            <td className='colored-cell'>0,07</td>
          </tr>
          <tr>
            <th>≥ 5 en {'<'} 6</th>
            <td className='colored-cell'>0,27</td>
            <td className='colored-cell'>0,22</td>
            <td className='colored-cell'>0,17</td>
            <td className='colored-cell'>0,14</td>
            <td className='colored-cell'>0,11</td>
            <td className='colored-cell'>0,09</td>
          </tr>
          <tr>
            <th>≥ 6 en {'<'} 7</th>
            <td className='colored-cell'>0,34</td>
            <td className='colored-cell'>0,26</td>
            <td className='colored-cell'>0,20</td>
            <td className='colored-cell'>0,16</td>
            <td className='colored-cell'>0,13</td>
            <td className='colored-cell'>0,10</td>
          </tr>
          <tr>
            <th>≥ 7 en {'<'} 8</th>
            <td className='colored-cell'>0,41</td>
            <td className='colored-cell'>0,29</td>
            <td className='colored-cell'>0,22</td>
            <td className='colored-cell'>0,18</td>
            <td className='colored-cell'>0,15</td>
            <td className='colored-cell'>0,12</td>
          </tr>
          <tr>
            <th>≥ 8 en {'<'} 9</th>
            <td className='colored-cell'>0,47</td>
            <td className='colored-cell'>0,33</td>
            <td className='colored-cell'>0,25</td>
            <td className='colored-cell'>0,20</td>
            <td className='colored-cell'>0,17</td>
            <td className='colored-cell'>0,13</td>
          </tr>
          <tr>
            <th>≥ 9 en {'<'} 10</th>
            <td>0,51</td>
            <td className='colored-cell'>0,36</td>
            <td className='colored-cell'>0,27</td>
            <td className='colored-cell'>0,22</td>
            <td className='colored-cell'>0,19</td>
            <td className='colored-cell'>0,14</td>
          </tr>
          <tr>
            <th>= 10</th>
            <td>0,55</td>
            <td className='colored-cell'>0,39</td>
            <td className='colored-cell'>0,30</td>
            <td className='colored-cell'>0,24</td>
            <td className='colored-cell'>0,20</td>
            <td className='colored-cell'>0,15</td>
          </tr>
        </tbody>
      </table>
    );
  }
}
 
export default TableVlekken7
