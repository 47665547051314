import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { Component } from "react";
import ChartWrapper from "./ChartWrapper"
import config from "react-global-configuration";

class ChartProcessingTimeDamageReports extends Component {
  constructor() {
    super();
    this.state = {
      options: {
        chart: {
          type: 'areaspline',
          spacing: [50, 20, 20, 20]
        },
        credits: {
          enabled: false
        },
        title: false,
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          title: {
            text: undefined
          }
        },
        legend: {
          align: 'left',
          symbolPadding: 0,
          symbolHeight: 0,
          symbolWidth: 0,
          symbolRadius: 0,
          useHTML: true,
          labelFormatter: function() {
            const color = this.color.slice(1);
            const r = parseInt(color.substr(0,2),16);
            const g = parseInt(color.substr(2,2),16);
            const b = parseInt(color.substr(4,2),16);
            const yiq = ((r*299)+(g*587)+(b*114))/1000;
            const fontColor = (yiq >= 128) ? '#000000' : '#ffffff';
            const yData = this.yData;
            return '<div class="legend-wrapper" style="background: ' + this.color + '"><div class="legend-square" style="background: ' + this.color + '"></div><div class="legend-label" style="color: ' + fontColor + ';">' + this.name + ': ' + yData.slice(-1).pop() + '</div></div>';
          }
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          areaspline: {
            stacking: 'normal',
            lineWidth: 0,
            marker: {
              enabled: false
            },
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        accessibility: {
          description: 'In deze grafiek over de doorlooptijd van de schademeldingen ziet u van januari 2019 tot nu hoeveel schademeldingen 2 jaar en ouder zijn, hoeveel tussen de 1 en 2 jaar oud zijn, hoeveel tussen de 0,5 en 1 jaar oud zijn, en hoeveel schademeldingen maximaal een half jaar oud zijn.'
        }
      }
    };
  }

  componentDidMount() {
    fetch(config.get('api_url') + '/data/img_schademeldingen.json', {cache: "no-store"})
      .then(res => res.json())
      .then(json => this.setState({
        producer: json.meta.producer,
        last_updated: json.meta.last_updated,
        options: {
          series: [{
            name: '2 jaar en ouder',
            data: json.data.map((json) => {
              return {x: json.datum, y: json.twee_jaar_en_ouder}
            }),
            color: '#F092CD'
          }, {
            name: '1-2 jaar oud',
            data: json.data.map((json) => {
              return {x: json.datum, y: json.tussen_1_en_2_jaar_oud}
            }),
            color: '#CA005D'
          }, {
            name: '0,5 - 1 jaar oud',
            data: json.data.map((json) => {
              return {x: json.datum, y: json.tussen_05_en_1_jaar_oud}
            }),
            color: '#8FCAE7'
          }, {
            name: '0,5 jaar oud',
            data: json.data.map((json) => {
              return {x: json.datum, y: json.half_jaar_oud}
            }),
            color: '#01689B'
          }]
        }
      }
    ))
  }

  render() {
    const titleSettings = {
      title: "Doorlooptijd afhandeling fysieke schademeldingen",
      classList: this.props.titleClassList
    }

    return (
      <div className="chart-processing-time-damage-reports chart-areaspline">
        <ChartWrapper
          source={this.state.producer}
          lastChanged={this.state.last_updated}
          titleSettings={titleSettings}
        >
          <HighchartsReact highcharts={Highcharts} options={this.state.options} />
        </ChartWrapper>
      </div>
    );
  }
}

export default ChartProcessingTimeDamageReports
