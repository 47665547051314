import React, {Component} from "react";
import RijksoverheidBlock from "../rijksoverheid-block/RijksoverheidBlock";

class ContentHeader extends Component {
  render() {
    return (
        <div className="content__header">
            <div className="content__intro">
            {this.props.children}
            </div>
            <RijksoverheidBlock /> 
        </div>
    );
  }
}

export default ContentHeader
