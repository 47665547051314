import Home from './components/Pages/Home';
import PageGasProduction from './components/Pages/PageGasProduction';
import PageDamageReports from './components/Pages/PageDamageReports';
import PageEarthquakes from './components/Pages/PageEarthquakes'
import PageSafety from "./components/Pages/PageSafety";
import AccessibilityStatement from "./components/Pages/AccessibilityStatement";
import PageVlekkenkaart from "./components/Pages/PageVlekkenkaart";
import PageVlekkentabel from './components/Pages/PageVlekkentabel';

export const routes = [
  {
    path: "/",
    label: "Dashboard Groningen",
    component: Home,
    routes: [
      {
        path: "/gaswinning-groningenveld",
        label: "Gaswinning Groningenveld",
        component: PageGasProduction,
      },
      {
        path: "/schadeafhandeling",
        label: "Schadeafhandeling",
        component: PageDamageReports,
      },
      {
        path: "/aardbevingen-groningen",
        label: "Aardbevingen Groningen",
        component: PageEarthquakes,
      },
      {
        path: "/versterken-en-veiligheid",
        label: "Versterken en Veiligheid",
        component: PageSafety,
      },
      {
        path: "/toegankelijkheidsverklaring",
        label: "Toegankelijkheidsverklaring",
        component: AccessibilityStatement,
      },
      {
        path: "/vlekkenkaart",
        label: "Vlekkenkaart",
        component: PageVlekkenkaart,
        container: 'container-no-gap',
        hide_footer: true,
      },
      {
        path: "/vlekkentabel",
        label: "Vlekkentabel",
        component: PageVlekkentabel,
      }
    ]
  }
];
