import React, {Component} from "react";
import ChartWrapper from "../Charts/ChartWrapper";

class InfoGroundSpeed extends Component {
  constructor() {
    super();
    this.state = {
      limitValue: 50,
    }
  }

  getColorClass(value) {
    if (this.state.limitValue === value) {
      return 'incident-params-value--grey';
    }

    return (value < this.state.limitValue) ? 'incident-params-value--green' : 'incident-params-value--red';
  }

  render() {
    return (
        <ChartWrapper source={this.props.source} lastChanged={this.props.lastChanged}>
          <div className="incident-params-pga card-neutral">
            <h3>Grondsnelheid (PGV)</h3>
            <div className={`incident-params-value ${this.getColorClass(this.props.data.value)}`}>{this.props.data.value} {this.props.data.unit}</div>
            <div className="incident-params-meta">
              <div>Uit sensordata van het KNMI berekende hoogste grondsnelheid tijdens de laatste aardbeving met een magnitude &gt;= 2,0:</div>
              <div>{this.props.meta}</div>
              <div>Grenswaarde: {this.state.limitValue} {this.props.data.unit}</div>
            </div>
          </div>
        </ChartWrapper>
    );
  }
}

export default InfoGroundSpeed;
