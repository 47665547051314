import React, {Component} from "react";
import './skiplink.scss';

class skiplink extends Component {
  render() {
    return (
      <a className="skiplink" href="#content-wrapper">Ga direct naar inhoud</a>
    );
  }
}

export default skiplink
