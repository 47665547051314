import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import config from 'react-global-configuration';
import configuration from './config';
import App from "./App";
import { routes } from "./routes";
import { generateAppRoutes } from "./navigation/utils";
import './index.scss';
import 'isomorphic-fetch';
import { polyfill } from 'es6-promise';
import 'array-flat-polyfill';

polyfill();

config.set(configuration);
const appRoutes = generateAppRoutes(routes);

const Layout = () => (
	<div>
		<App routes={appRoutes} />
	</div>
);

render(<Layout />, document.getElementById('root'));
