import React, { Component } from 'react';
import ChartProcessingTimeDamageReports from "../Charts/ChartProcessingTimeDamageReports";
import ContentHeader from "../partials/content-header/ContentHeader";
import FysiekeSchade from "../../assets/images/ncg/png/fysieke-schade.png"
import FysiekeSchadeMob from "../../assets/images/ncg/png/fysieke-schade-mob.png"
import Schademelding from "../../assets/images/ncg/png/schademelding.png"
import SchademeldingMob from "../../assets/images/ncg/png/schademelding-mob.png"
import Waardedaling from "../../assets/images/ncg/png/waardedaling.png"
import WaardedalingMob from "../../assets/images/ncg/png/waardedaling-mob.png"
import ImmaterieleSchade from "../../assets/images/ncg/png/immateriele-schade.png"
import ImmaterieleSchadeMob from "../../assets/images/ncg/png/immateriele-schade-mob.png"
import TabelSchadevergoeding from "../../assets/images/ncg/png/tabel-schadevergoeding.png";
import TabelSchadevergoedingMob from "../../assets/images/ncg/png/tabel-schadevergoeding-mob.png";
import ChartWrapper from '../Charts/ChartWrapper';
import Picture from '../partials/Picture/Picture';

class PageDamageReports extends Component {
  render() {
    return <div className="page-damage-reports">
      <ContentHeader>
        <h1>Schadeafhandeling</h1>
        <p>Aardbevingen in Groningen veroorzaken schade aan huizen en gebouwen en zorgen
          voor onzekerheid bij bewoners. <a href="https://schadedoormijnbouw.nl/" rel="noopener noreferrer" target="_blank">
            Het Instituut Mijnbouwschade Groningen</a> (IMG) handelt de schades af.
            Dit gebeurt onafhankelijk van de Nederlandse Aardolie Maatschappij (NAM) en de overheid.
        </p>
        <p>Het IMG ontfermt zich over alle vormen van schade. Het gaat ook om schade die mensen hebben omdat de waarde van huis is gedaald als gevolg van gaswinning, en schade doordat mensen klachten zoals stress en angst hebben ervaren, de zogenaamde immateriële schade.
          Uitgebreide actuele informatie over de schadeafhandeling is te vinden op <a href="https://www.schadedoormijnbouw.nl/dashboard?topic=-" rel="noopener noreferrer" target="_blank">het online Dashboard van het IMG</a>.
        </p>
      </ContentHeader>

      <div className="pagebreak"></div>

      <div className="content__block">
        <ChartWrapper source="IMG">
          <Picture defaultSource={TabelSchadevergoeding} mobileSource={TabelSchadevergoedingMob} />
        </ChartWrapper>
      </div>

      <div className="pagebreak"></div>

      <div className="content__block">
        <ChartWrapper source="IMG">
          <Picture defaultSource={Schademelding} mobileSource={SchademeldingMob} />
        </ChartWrapper>
      </div>

      <div className="pagebreak"></div>

      <div className="content__block">
        <ChartWrapper source="IMG">
          <Picture defaultSource={FysiekeSchade} mobileSource={FysiekeSchadeMob} />
        </ChartWrapper>
      </div>

      <div className="pagebreak"></div>

      <div className="content__block">
        <ChartWrapper source="IMG">
          <Picture defaultSource={Waardedaling} mobileSource={WaardedalingMob} />
        </ChartWrapper>
      </div>

      <div className="pagebreak"></div>

      <div className="content__block">
        <ChartWrapper source="IMG">
          <Picture defaultSource={ImmaterieleSchade} mobileSource={ImmaterieleSchadeMob} />
        </ChartWrapper>
      </div>

      <div className="pagebreak"></div>

      <div className="content__block">
        <ChartProcessingTimeDamageReports />
      </div>
    </div>
    ;
  }
}

export default PageDamageReports;
