import React, { Component } from 'react';

class TableVlekken1 extends Component {
  render() {
    return (
      <table>
        <thead>
          <tr>
            <td></td>
            <th className='first-row' colSpan={6}>Lengte gebouw in meters</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>Hoogte gebouw in meters</th>
            <th>= 10</th>
            <th>{'>'} 10 en ≤ 15</th>
            <th>{'>'} 15 en ≤ 20</th>
            <th>{'>'} 20 en ≤ 25</th>
            <th>{'>'} 25 en ≤ 30</th>
            <th>{'>'} 30 en ≤ 40</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>≥ 4 en {'<'} 5</th>
            <td className='colored-cell'>0,46</td>
            <td className='colored-cell'>0,33</td>
            <td className='colored-cell'>0,27</td>
            <td className='colored-cell'>0,23</td>
            <td className='colored-cell'>0,21</td>
            <td className='colored-cell'>0,17</td>
          </tr>
          <tr>
            <th>≥ 5 en {'<'} 6</th>
            <td>0,52</td>
            <td className='colored-cell'>0,38</td>
            <td className='colored-cell'>0,30</td>
            <td className='colored-cell'>0,26</td>
            <td className='colored-cell'>0,23</td>
            <td className='colored-cell'>0,19</td>
          </tr>
          <tr>
            <th>≥ 6 en {'<'} 7</th>
            <td>0,58</td>
            <td className='colored-cell'>0,43</td>
            <td className='colored-cell'>0,34</td>
            <td className='colored-cell'>0,29</td>
            <td className='colored-cell'>0,26</td>
            <td className='colored-cell'>0,21</td>
          </tr>
          <tr>
            <th>≥ 7 en {'<'} 8</th>
            <td>0,62</td>
            <td className='colored-cell'>0,47</td>
            <td className='colored-cell'>0,37</td>
            <td className='colored-cell'>0,32</td>
            <td className='colored-cell'>0,28</td>
            <td className='colored-cell'>0,23</td>
          </tr>
          <tr>
            <th>≥ 8 en {'<'} 9</th>
            <td>0,67</td>
            <td>0,50</td>
            <td className='colored-cell'>0,41</td>
            <td className='colored-cell'>0,34</td>
            <td className='colored-cell'>0,30</td>
            <td className='colored-cell'>0,25</td>
          </tr>
          <tr>
            <th>≥ 9 en {'<'} 10</th>
            <td>0,70</td>
            <td>0,53</td>
            <td className='colored-cell'>0,43</td>
            <td className='colored-cell'>0,37</td>
            <td className='colored-cell'>0,32</td>
            <td className='colored-cell'>0,26</td>
          </tr>
          <tr>
            <th>= 10</th>
            <td>0,80</td>
            <td>0,61</td>
            <td className='colored-cell'>0,49</td>
            <td className='colored-cell'>0,42</td>
            <td className='colored-cell'>0,37</td>
            <td className='colored-cell'>0,30</td>
          </tr>
        </tbody>
      </table>
    );
  }
}
 
export default TableVlekken1
