import React, { Component } from "react";
import Skiplink from "../skiplink/Skiplink";

class header extends Component {

  render() {
    return (
      <header>
        <Skiplink />
        <div className="logo">
            <div className="wrapper">
                <img
                  data-fallback="../../../logo-fallback-ro.svg"
                  src="../../../logo-ro.svg"
                  alt="Rijksoverheid logo"
                  id="logotype"
                />
            </div>
        </div>
    </header>
  )}
}

export default header
