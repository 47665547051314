import React, { Component } from "react";
import {Link} from "react-router-dom";
import './footer.scss';

class Footer extends Component {

  render() {
    return (
    <footer className="site-footer">
        <div className="wrapper">
            <div className="column">
                <h2>Over deze site</h2>
                <ul>
                    <li>
                      <Link to="/toegankelijkheidsverklaring">Toegankelijkheidsverklaring</Link>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
  )}
}

  export default Footer
