import React, { Component } from 'react';
import ChartGasProduction from "../Charts/ChartGasProduction";
import ContentHeader from "../partials/content-header/ContentHeader";

class PageGasProduction extends Component {
  render() {
    return <div className="page-gas-production">
      <ContentHeader>
        <h1>Gaswinning Groningenveld</h1>
        <p>
          Op 19 april 2024 is de wet in werking getreden die een verbod op gaswinning uit het Groningenveld regelt.
          De gaswinning is beëindigd. Het historische verloop van de gaswinning is in de grafiek hieronder terug te
          zien.
        </p>
      </ContentHeader>
      <div className="pagebreak"></div>

      <div className="content__block">
        <ChartGasProduction />
      </div>
    </div>
    ;
  }
}

export default PageGasProduction;
