import React, { Component } from 'react';

class AccessibilityStatement extends Component {
  render() {
    return <div className="page-accessibility-statement">
      <div className="content__block">
        <h1>Toegankelijkheidsverklaring</h1>
        <p>
          Het Ministerie van Economische Zaken en Klimaat wil dat iedereen deze website kan gebruiken.
          Komt u toch een pagina tegen die niet toegankelijk is? Dan kunt u dit aan ons melden.
        </p>
      </div>

      <div className="content__block">
        <h2>Wat is een toegankelijke website?</h2>
        <p>
          Een toegankelijke website is voor alle doelgroepen beter te gebruiken. Daarom gelden er
          functioneel-technische en redactionele toegankelijkheidseisen (voorheen: webrichtlijnen)
          voor websites van de overheid. Deze zijn vastgelegd in de toegankelijkheidsstandaard
          Digitoegankelijk EN 301 549.
        </p>
      </div>

      <div className="content__block">
      <h2>Verklaring</h2>
        <p>
          Bekijk de toegankelijkheidsverklaring voor de Dashboard Groningen website op <a href="https://www.toegankelijkheidsverklaring.nl/register/3184">https://www.toegankelijkheidsverklaring.nl/register/3184</a>.
        </p>
        <p>
          Een overzicht van alle websites, apps en verklaringen van Ministerie van Economische Zaken
          en Klimaat is beschikbaar via de link <a href="https://www.toegankelijkheidsverklaring.nl/register?instantie=ministerie+van+economische+zaken+en+klimaat&naam">https://www.toegankelijkheidsverklaring.nl/register?instantie=ministerie+van+economische+zaken+en+klimaat&naam</a>.
        </p>
      </div>

      <div className="content__block">
        <h2>Nalevingsstatus: eerste maatregelen genomen</h2>
        <p>
          Ministerie van Economische Zaken en Klimaat verklaart dat de eerste maatregelen zijn genomen
          om de Dashboard Groningen website te laten voldoen aan het Tijdelijk besluit digitale toegankelijkheid overheid.
          <a className="accessibility__link" href="https://www.toegankelijkheidsverklaring.nl/register/3184">
            <img className="accessibility__img" src="https://www.toegankelijkheidsverklaring.nl/files/verklaring/label/45c69328f101de0fc2b12e98d63cfc31.3184.svg" alt="Het toegankelijkheidslabel van Dashboard Groningen. Volg de link voor de toegankelijke versie van dit label." />
          </a>
        </p>
      </div>

      <div className="content__block">
        <h2>Feedback en contactgegevens</h2>
        <p>
          Loopt u tegen een toegankelijkheidsprobleem aan? Of heeft u een vraag of opmerking over toegankelijkheid? Neem dan contact op via burgercorrespondentie@minezk.nl.
        </p>
      </div>

      <div className="content__block">
        <h2>Wat kunt u van ons verwachten?</h2>
        <p>
          We informeren u over de voortgang en de uitkomst.
          Bent u niet tevreden met de manier waarop uw klacht is behandeld?
          Of hebben we niet op tijd gereageerd? Dan kunt u contact opnemen met <a href="https://www.nationaleombudsman.nl/klacht-indienen/uw-klacht">Nationale Ombudsman</a>.
        </p>
      </div>
    </div>
    ;
  }
}

export default AccessibilityStatement;
