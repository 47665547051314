import React, {Component} from "react";
import config from 'react-global-configuration';
import InfoGroundAcceleration from "./InfoGroundAcceleration";
import InfoGroundSpeed from "./InfoGroundSpeed";

class IncidentParametersWrapper extends Component {
  constructor() {
    super();
    this.state = {groundMotions: {}, event: {}, source: "", updated: ""};
  }

  componentDidMount() {
    fetch(config.get('api_url') + '/data/knmi_incident-parameters.json', {cache: "no-store"})
      .then(res => res.json())
      .then(json => this.setState({
        groundMotions: json.data.ground_motions,
        event: json.data.event_information,
        source: json.meta.producer,
        updated: json.meta.last_updated
      }));
  }

  roundNumber(num) {
    const rounded = Math.round((num + Number.EPSILON) * 100) / 100;
    const value = (rounded !== 0) ? rounded : 0.01;
    
    return value;
  }

  render() {
    const location = this.state.event.place;
    const dateTime = this.state.event.origin_time;
    const magnitude = this.state.event.magnitude;
    const metaData = `${location}; M=${magnitude}; ${dateTime}`;
    let accelerationData = {
      value: '',
      unit: ''
    }

    let speedData = {
      value: '',
      unit: ''
    }

    if (this.state.groundMotions.pga) {
      accelerationData.value = this.roundNumber(this.state.groundMotions.pga.max);
      accelerationData.unit = this.state.groundMotions.pga.units;
    }

    if (this.state.groundMotions.pgv) {
      speedData.value = this.roundNumber(this.state.groundMotions.pgv.max);
      speedData.unit = this.state.groundMotions.pgv.units;
    }

    return (
      <div className="incident-params-wrapper">
        <InfoGroundAcceleration 
          source={this.state.source}
          lastChanged={this.state.updated}
          meta={metaData}
          data={accelerationData}
        ></InfoGroundAcceleration>

        <InfoGroundSpeed
          source={this.state.source}
          lastChanged={this.state.updated}
          meta={metaData}
          data={speedData}
        ></InfoGroundSpeed>
      </div>
    );
  }
}

export default IncidentParametersWrapper
