import React from "react";
import { Link } from "react-router-dom";
import './menu.scss';

const Menu = () => {
  return (
    <nav>
      <ul className="menu">
        <li>
          <Link className="menu-link" to={`/schadeafhandeling`}>
            <span className="menu-link__title">1. Afhandeling schademeldingen</span>
            <span className="menu-link__description">Feiten en cijfers over de schadeafhandeling</span>
          </Link>
        </li>
        <li>
          <Link className="menu-link" to={`/versterken-en-veiligheid`}>
            <span className="menu-link__title">2. Versterking</span>
            <span className="menu-link__description">Voortgang versterkingsoperatie</span>
          </Link>
        </li>
        <li>
          <Link className="menu-link" to={`/aardbevingen-groningen`}>
            <span className="menu-link__title">3. Aardbevingen Groningen</span>
            <span className="menu-link__description">Aantal aardbevingen in het Groningenveld</span>
          </Link>
        </li>
        <li>
          <Link className="menu-link" to={`/gaswinning-groningenveld`}>
            <span className="menu-link__title">4. Gaswinning</span>
            <span className="menu-link__description">Afbouw gaswinning Groningenveld</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}


export default Menu;
