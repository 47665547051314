import React, { Component } from 'react';

class TableVlekken8 extends Component {
  render() {
    return (
      <table>
        <thead>
          <tr>
            <td></td>
            <th className='first-row' colSpan={6}>Lengte gebouw in meters</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>Hoogte gebouw in meters</th>
            <th>= 40</th>
            <th>{'>'} 40 en ≤ 60</th>
            <th>{'>'} 60 en ≤ 80</th>
            <th>{'>'} 80 en ≤ 100</th>
            <th>{'>'} 100 en ≤ 120</th>
            <th>{'>'} 120 en ≤ 160</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>≥ 4 en {'<'} 5</th>
            <td className='colored-cell'>0,20</td>
            <td className='colored-cell'>0,14</td>
            <td className='colored-cell'>0,11</td>
            <td className='colored-cell'>0,09</td>
            <td className='colored-cell'>0,07</td>
            <td className='colored-cell'>0,05</td>
          </tr>
          <tr>
            <th>≥ 5 en {'<'} 6</th>
            <td className='colored-cell'>0,25</td>
            <td className='colored-cell'>0,17</td>
            <td className='colored-cell'>0,13</td>
            <td className='colored-cell'>0,11</td>
            <td className='colored-cell'>0,09</td>
            <td className='colored-cell'>0,07</td>
          </tr>
          <tr>
            <th>≥ 6 en {'<'} 7</th>
            <td className='colored-cell'>0,29</td>
            <td className='colored-cell'>0,20</td>
            <td className='colored-cell'>0,15</td>
            <td className='colored-cell'>0,12</td>
            <td className='colored-cell'>0,10</td>
            <td className='colored-cell'>0,08</td>
          </tr>
          <tr>
            <th>≥ 7 en {'<'} 8</th>
            <td className='colored-cell'>0,32</td>
            <td className='colored-cell'>0,23</td>
            <td className='colored-cell'>0,18</td>
            <td className='colored-cell'>0,14</td>
            <td className='colored-cell'>0,12</td>
            <td className='colored-cell'>0,09</td>
          </tr>
          <tr>
            <th>≥ 8 en {'<'} 9</th>
            <td className='colored-cell'>0,37</td>
            <td className='colored-cell'>0,26</td>
            <td className='colored-cell'>0,20</td>
            <td className='colored-cell'>0,16</td>
            <td className='colored-cell'>0,13</td>
            <td className='colored-cell'>0,10</td>
          </tr>
          <tr>
            <th>≥ 9 en {'<'} 10</th>
            <td className='colored-cell'>0,41</td>
            <td className='colored-cell'>0,28</td>
            <td className='colored-cell'>0,22</td>
            <td className='colored-cell'>0,18</td>
            <td className='colored-cell'>0,15</td>
            <td className='colored-cell'>0,11</td>
          </tr>
          <tr>
            <th>= 10</th>
            <td className='colored-cell'>0,45</td>
            <td className='colored-cell'>0,31</td>
            <td className='colored-cell'>0,24</td>
            <td className='colored-cell'>0,19</td>
            <td className='colored-cell'>0,16</td>
            <td className='colored-cell'>0,12</td>
          </tr>
        </tbody>
      </table>
    );
  }
}
 
export default TableVlekken8
