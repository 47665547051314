import React from 'react';
import ContentHeader from "../partials/content-header/ContentHeader";
import Versterking from "../../assets/images/ncg/png/versterking.png"
import VersterkingMob from "../../assets/images/ncg/png/versterking-mob.png"
import VersterkingProgrammas1 from "../../assets/images/ncg/png/versterking-programmas-1.png"
import VersterkingProgrammas1Mob from "../../assets/images/ncg/png/versterking-programmas-1-mob.png"
import VersterkingProgrammas2 from "../../assets/images/ncg/png/versterking-programmas-2.png"
import VersterkingProgrammas2Mob from "../../assets/images/ncg/png/versterking-programmas-2-mob.png"
import Voortgang from "../../assets/images/ncg/png/voortgang.png"
import VoortgangMob from "../../assets/images/ncg/png/voortgang-mob.png"
import ChartWrapper from '../Charts/ChartWrapper';
import Picture from '../partials/Picture/Picture';

const PageSafety = () => {
    return <div className="page-safety">
      <ContentHeader>
        <h1>Versterking</h1>
        <p>
          Nationaal Coördinator Groningen (NCG) onderzoekt en versterkt gebouwen die in het aardbevingsgebied in
          Groningen staan en die door de gaswinning (mogelijk) onveilig zijn geworden. De versterkingsoperatie is
          breder dan het herstel van fysieke veiligheid. NCG draagt vanuit de versterkingsopgave ook bij aan
          verduurzaming, de verbetering van de leefomgeving, leefbaarheid en sociale verbinding.
          Onderstaande figuren geven weer hoe de voortgang van de versterking vordert, met een nadere specificatie
          van een aantal programma’s en hoe bewoners de dienstverlening van NCG hebben ervaren.
          Voor meer informatie over de processen en terminologie kunt u <a href="https://www.nationaalcoordinatorgroningen.nl/versterken/versterking-in-stappen" rel="noopener noreferrer" target="_blank">de website</a> van NCG raadplegen.
        </p>
        <div className="pagebreak"></div>
      </ContentHeader>

      <div className="pagebreak"></div>

      <div className="content__block">

        <ChartWrapper source={'NCG'}>
          <Picture defaultSource={Versterking} mobileSource={VersterkingMob}/>
        </ChartWrapper>

        <p className="chart__disclaimer">
          Het aantal adressen in eerste fasen van het versterkingstraject neemt elk jaar af en het aantal adressen
          dat voldoet aan de landelijke Meijdam-norm neemt elk jaar toe. De groei van de totale werkvoorraad van
          NCG is het gevolg van opnames op verzoek van bewoners en de jaarlijkse risicoanalyse in het aardbevingsgebied.
        </p>

        <div className="pagebreak"></div>

        <ChartWrapper source={'NCG'}>
          <Picture defaultSource={VersterkingProgrammas1} mobileSource={VersterkingProgrammas1Mob}/>
        </ChartWrapper>

        <ChartWrapper source={'NCG'}>
          <Picture defaultSource={VersterkingProgrammas2} mobileSource={VersterkingProgrammas2Mob}/>
        </ChartWrapper>

        <p className="chart__disclaimer">
          Binnen de totale werkvoorraad van de NCG vragen sommige gebouwtypen vragen om een specifieke aanpak en zijn
          door NCG in specifieke programma’s ondergebracht.
          Bij de versterking van deze gebouwen moet rekening gehouden worden met de activiteiten, het gebruik en de
          gebruikers van een gebouw, of met specifieke regelgeving, zoals bij agro en erfgoed. In een aantal gevallen
          wordt de versterking of vernieuwing van deze gebouwen aangegrepen om het gebouw toekomstbestendig te maken
          en beter aan te laten sluiten op veranderend gebruik. Denk bijvoorbeeld aan het inrichten van nieuwe
          kindcentra in het scholenprogramma.
        </p>

        <div className="pagebreak"></div>

        <ChartWrapper source={'NCG'}>
          <Picture defaultSource={Voortgang} mobileSource={VoortgangMob}/>
        </ChartWrapper>

        <p className="chart__disclaimer">
          Het algemene tevredenheidscijfer van bewoners in 2023 is een 4,9. In de <a
          href="https://www.nationaalcoordinatorgroningen.nl/documenten/rapporten/2023/11/27/rapport-bewonerstevredenheidsonderzoek-ncg-oktober-2023"
          rel="noopener noreferrer" target="_blank">
          uitkomsten van het tevredenheidsonderzoek in 2023</a> valt op dat de bewonerstevredenheid toeneemt naar mate
          bewoners verder in het versterkingstraject komen. Dit lijkt voornamelijk samen te hangen met de mate van
          contact tussen bewoners en NCG: in de eerste fasen is er beperkt contact tussen bewoners en NCG, verder in het
          versterkingstraject neemt het contact toe.
        </p>
      </div>
    </div>
      ;
}

export default PageSafety;
