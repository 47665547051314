import React, { Component } from 'react';
import ChartEarthquakes from '../Charts/ChartEarthquakes';
import ContentHeader from '../partials/content-header/ContentHeader';
import IncidentParametersWrapper from '../IncidentParameters/IncidentParametersWrapper';
import ChartsTrendParameters from '../Charts/ChartsTrendParameters';

class PageEarthquakes extends Component {
  render() {
    return (
      <div className="page-earthquakes">
        <ContentHeader>
          <h1>Aardbevingen Groningen</h1>
          <p>
              Door gaswinning uit het Groningenveld ontstaan in Groningen aardbevingen. Met het definitief beëindigen
              van de gaswinning in 2024 is de oorzaak van aardbevingen weggenomen. Maar ook na het beëindigen van de
              gaswinning zal er nog enige tijd beweging blijven in de ondergrond in Groningen. In de wet staat dat de
              bodembeweging nog minimaal 30 jaar gemonitord wordt.
          </p>
        </ContentHeader>

        <div className="content__block">
          <ChartEarthquakes />
        </div>

        <div className="content__block">
          <ChartsTrendParameters />
        </div>

        <div className="content__block">
          <h2>Incidentparameters</h2>
          <p className="chart__intro">
            Incidentparameters helpen om de hevigheid van een aardbeving aan te geven Het KNMI-meetnetwerk meet de
            versnelling en de snelheid van de grond in Groningen. Op deze pagina wordt de piekgrondversnelling en
            piekgrondsnelheid van de laatste aardbevingweergegeven. De piekgrondversnelling is de hoogste grondversnelling
            die tijdens een aardbeving wordt gemeten. De piekgrondversnelling wordt afgekort tot PGA, van het Engelse Peak
            Ground Acceleration. De versnelling wordt weergegeven in rekeneenheid g, dat staat voor valversnelling. Daarnaast
            wordt ook de PGV (van het Engelse Peak Ground Velocity) weergeven. Dit is de piekgrondsnelheid, oftewel de
            grootste snelheid die tijdens een aardbeving wordt gemeten. De grondversnelling wordt in millimeter per
            seconde (mm/s) weergegeven.
          </p>
          <IncidentParametersWrapper></IncidentParametersWrapper>
        </div>
      </div>
    );
  }
}

export default PageEarthquakes;
