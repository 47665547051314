import React, {Component} from "react";
import ChartWrapper from "./ChartWrapper";
import config from 'react-global-configuration';
import ChartTrendParametersTotal from "./ChartTrendParametersTotal";
import ChartTrendParametersDensity from "./ChartTrendParametersDensity";

class ChartTrendParameters extends Component {
  constructor() {
    super();
    this.state = {
      meta: [],
      categories: [],
      totalSeries: [],
      densitySeries: [],
    };
  }

  componentDidMount() {
    fetch(config.get('api_url') + '/data/knmi_trend-parameters.json', {cache: "no-store"})
      .then(res => res.json())
      .then(json => this.setState({
        meta: json.meta,
        categories: json.data.dates,
        totalSeries: json.data.counts,
        densitySeries: json.data.densities
      }));
  }

  render() {
    const titleSettings = {
      title: "Trendparameters",
      classList: this.props.titleClassList
    }
    const intro = "Als er een beving plaatsvindt waarbij parameters worden overschreden, dan moet NAM een analyse doen. In de Mijnbouwregeling zijn verschillende parameters opgenomen: trendparameters en incidentparameters. Trendparameters helpen om vroegtijdig een toename of afname van de hoeveelheid aardbevingen op te merken."

    return (
        <ChartWrapper
          source={this.state.meta.producer}
          lastChanged={this.state.meta.last_updated}
          titleSettings={titleSettings}
          intro={intro}
        >
          <ChartTrendParametersTotal
            categories={this.state.categories}
            series={this.state.totalSeries}
            key={this.state.totalSeries[0]}
          />

          <ChartTrendParametersDensity
            categories={this.state.categories}
            series={this.state.densitySeries}
            key={this.state.densitySeries[0]}
          />
        </ChartWrapper>
    );
  }
}

export default ChartTrendParameters;
