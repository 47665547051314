import React, {Component} from "react";

class ChartWrapper extends Component {
  render() {
    let title;
    let source;
    let lastChanged;
    let intro;
    let sizeClass = '';
    let description;

    if (this.props.titleSettings) {
      if (this.props.titleSettings.classList) {
        const classes = this.props.titleSettings.classList.join(" ")
        title = <h3 className={classes}>{this.props.titleSettings.title}</h3>
      } else {
        title = <h2>{this.props.titleSettings.title}</h2>
      }
    }

    if (this.props.source) {
      source = <span className="source">Bron: {this.props.source}</span>
    }

    if (this.props.lastChanged) {
      lastChanged = <span className="last-change">Laatst bijgewerkt: {this.props.lastChanged}</span>
    }

    if (this.props.intro) {
      intro = <p className="chart__intro">{this.props.intro}</p>
    }

    if (this.props.halfWidth) {
      sizeClass = "chart__container--half-width"
    }

    if (this.props.description) {
      description = <em>{this.props.description}</em>
    }

    return (
      <div className="chart">
        {title}
        {intro}
        <div className={sizeClass}>
          <div className="chart__body">
            {this.props.children}
          </div>
          <div className="chart__meta">
            {source}
            {lastChanged}
            {description}
          </div>
        </div>
      </div>
    );
  }
}

export default ChartWrapper
