import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, {Component} from "react";
import ChartWrapper from "./ChartWrapper"
import config from 'react-global-configuration';

class ChartEarthquakes extends Component {
  constructor() {
    super();
    this.state = {
      options: {
        chart: {
          type: 'column',
          spacing: [50, 20, 20, 20]
        },
        credits: {
          enabled: false
        },
        title: false,
        yAxis: {
          title: {
            text: "Aantal aardbevingen"
          },
          reversedStacks: false
        },
        legend: {
          title: {
            text: 'Selecteer de magnitude',
            style: {
              'fontWeight': 'normal',
              'padding': 10
            }
          },
          align: 'right',
          verticalAlign: 'top',
          symbolRadius: 0,
          squareSymbol: true
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            groupPadding: 0.05
          },
        },
        accessibility: {
          description: 'De grafiek laat van 1991 tot nu zien hoeveel aardbevingen er in Groningen zijn geweest per jaar, opgesplitst per magnitude.'
        },
        reversedStacks: true
      },
      meta: []
    }
  }

  componentDidMount() {
    fetch(config.get('api_url') + '/data/knmi_aantal-aardbevingen.json', {cache: "no-store"})
      .then(res => res.json())
      .then(json => this.setState({
        meta: json.meta,
        options: {
          series: [{
            name: '<1.0',
            data: json.data.series["1.0"],
            color: '#8FCAE7'
          }, {
            name: '<1.5',
            data: json.data.series["1.5"],
            color: '#007BC7'
          }, {
            name: '<2.0',
            data: json.data.series["2.0"],
            color: '#01689B'
          }, {
            name: '<2.5',
            data: json.data.series["2.5"],
            color: '#F092CD'
          }, {
            name: '<3.0',
            data: json.data.series["3.0"],
            color: '#CA005D'
          }, {
            name: '<3.5',
            data: json.data.series["3.5"],
            color: '#A90061'
          }, {
            name: '>3.5',
            data: json.data.series["max"],
            color: '#42145F'
          }],
          tooltip: {
            formatter: function () {
              const series = this.series.chart.series;
              const name = this.point.series.getName().substring(1);
              const pointIndex = this.series.index;
              let prevName;

              if (pointIndex > 0) {
                prevName = series[pointIndex - 1].getName().substring(1);
              }

              if  (pointIndex === (series.length - 1)) {
                return "<strong>" + this.x + "</strong><br />aantal aardbevingen boven de " + name + " is " + this.y;
              } else if (pointIndex > 0) {
                return "<strong>" + this.x + "</strong><br />aantal aardbevingen tussen de " + prevName + " en " + name + " is " + this.y;
              } else {
                return "<strong>" + this.x + "</strong><br />aantal aardbevingen onder de " + name + " is " + this.y;
              }
            }
          },
          xAxis: {
            categories: json.data.categories,
            labels: {
              rotation: 0,
              staggerLines: 2
            }
          },
        }
      }));
  }

  render() {
    const titleSettings = {
      title: "Aantal aardbevingen in het Groningenveld",
      classList: this.props.titleClassList
    }

    return (
      <div className="chart-earthquakes">
          <ChartWrapper
            source={this.state.meta.producer}
            lastChanged={this.state.meta.last_updated}
            titleSettings={titleSettings}
          >
            <HighchartsReact highcharts={Highcharts} options={this.state.options}/>
          </ChartWrapper>
          <div className="chart__disclaimer">
            <p>Nieuwe aardbevingen worden gemeten en automatisch geanalyseerd door het KNMI. Het kan voorkomen dat, na handmatige analyse, de beving toch toegekend wordt aan een andere bron van geïnduceerde seismiciteit, waardoor de lijst van aardbevingen bij het Groningenveld kan wijzigen.</p>
            <p>Sinds 2014 is het KNMI-meetnetwerk uitgebreid. Sindsdien worden alle aardbevingen met een magnitude groter of gelijk aan M1,2 gemeten in het Groningenveld.</p>
          </div>
      </div>
    );
  }
}

export default ChartEarthquakes
