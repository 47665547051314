import React, {Component} from "react";
import './rijksoverheid-block.scss';

class RijksoverheidBlock extends Component {
  render() {
    return (
        <div className="rijksoverheid-block">
            Meer informatie over gaswinning in Groningen:
            <a href="https://www.rijksoverheid.nl/onderwerpen/gaswinning-in-groningen" target="_blank" rel="noopener noreferrer">Ga naar Rijksoverheid.nl</a>
      </div>
    );
  }
}

export default RijksoverheidBlock
