import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React, { Component } from 'react';
import ChartWrapper from './ChartWrapper';
import config from 'react-global-configuration';

class ChartGasProduction extends Component {
  constructor() {
    super();
    this.state = {meta: [], categories: [], series: []};
  }

  componentDidMount() {
    fetch(config.get('api_url') + '/data/nlog_gas_production.json', {cache: "no-store"})
      .then(res => res.json())
      .then(json => this.setState({
        meta: json.meta,
        categories: json.data.categories,
        series: json.data.series
      }));
  }

  render() {
    const titleSettings = {
      title: 'Historische Gaswinning Groningenveld',
      classList: this.props.titleClassList
    }
    const options = {
      chart: {
        type: 'column',
        spacing: [50, 20, 20, 20]
      },
      credits: {
        enabled: false
      },
      title: false,
      xAxis: {
        categories: this.state.categories,
        tickPositioner: function() {
          const positions = [],
            ext = this.getExtremes(),
            xMax = Math.round(ext.max),
            xMin = Math.round(ext.min);

          if (xMax % 2 === 0) {
            for (let i = xMin; i < xMax; i+=1) {
              if (i % 2 === 0) {
                positions.push(i);
              }
            }
          } else {
            for (let i = xMin; i < xMax; i+=1) {
              if (i % 2 !== 0) {
                positions.push(i);
              }
            }
          }

          positions.push(xMax);

          return positions;
        },
        tickInterval: 1,
        title: {
          text: "Gasjaren"
        }
      },
      yAxis: {
        title: {
          text: 'Gewonnen gas in miljarden (Nm3)'
        },
        labels: {
          formatter: function () {
            return Highcharts.numberFormat(this.value / 1000000, 0);
          }
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        },
      },
      tooltip: {
        formatter: function () {
          return Highcharts.numberFormat(this.y / 1000000, 2, ',', '') + " miljard Nm3";
        }
      },
      series: [{
        data: this.state.series,
        color: '#2B6697',
      }],
      accessibility: {
        description: 'In deze grafiek ziet u per gasjaar hoeveel miljard kubieke meter gas er is gewonnen uit het Groningenveld van 1971 tot nu.'
      }
    };
    return (
      <div className="chart-gas-production">
        <ChartWrapper
          source={this.state.meta.producer}
          lastChanged={this.state.meta.last_updated}
          titleSettings={titleSettings}
        >
          <HighchartsReact highcharts={Highcharts} options={options} />
        </ChartWrapper>
      </div>
    );
  }
}

export default ChartGasProduction
