import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, {Component} from "react";

class ChartTrendParametersTotal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: 'line',
          spacing: [50, 20, 20, 20]
        },
        credits: {
          enabled: false
        },
        title: {
          text: 'Aantal aardbevingen'
        },
        legend: {
          layout: 'vertical',
          align: 'center',
          verticalAlign: 'bottom',
          symbolRadius: 0,
          symbolHeight: 10,
          symbolWidth: 30,
          itemMarginBottom: 12,
          symbolPadding: 5,
          squareSymbol: false,
          itemStyle: {
            'fontWeight': 400,
            'cursor': 'default'
          },
          width: "90%",
          title: {
            text: 'Niveau seismische activiteit'
          },

        },
        yAxis: {
          title: {
            text: "n/jaar"
          },
          tickInterval: 20,
          gridLineWidth: 0,
          plotBands: [{
            from: 0,
            to: 30,
            color: '#CCE0F1'
          }, {
            from: 30,
            to: 40,
            color: '#DDEFF8'
          }, {
            from: 40,
            to: 100,
            color: '#EFB2CE'
          }]
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function (e) {
                  e.preventDefault();
              }
            },
            states: {
              hover: {
                enabled: true,
                lineWidth: 2
              }
            }
          }
        },
        series: [{
          data: this.props.series,
          color: '#007BC7',
          showInLegend: false
        },
        {
          name: '40+ = Hoge activiteit',
          color: '#EFB2CE',
          type: 'area'
        }, {
          name: '30 - 40 = Bovengemiddelde activiteit',
          color: '#DDEFF8',
          type: 'area'
        }, {
          name: '0 - 30 = Lage activiteit',
          color: '#CCE0F1',
          type: 'area'
        }],
        xAxis: {
          labels: {
            formatter: function () {
              return this.value.substring(0,4);
            }
          },
          categories: this.props.categories,
          tickPositioner: function () {
            const positions = [0];
            const categories = this.categories;
            let totalTicks = 0;
            let prevCategory = 0;

            for (let i = 0, l = categories.length; i < l; i += 1) {
              const category = parseInt(categories[i].substring(0,4));

              if (category !== prevCategory) {
                let interval = 365;
                prevCategory = category;

                if (category % 4 === 0) {
                  interval = 366;
                }

                totalTicks += interval;
                positions.push(totalTicks);
              }
            }

            return positions;
          }
        },
        tooltip: {
          formatter: function () {
            const date = new Date(this.x);
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const formattedDate = dd + '-' + mm + '-' + yyyy;
            return '<b>' + formattedDate + '</b><br/>Aantal:<b>' + this.y + '</b>';
          }
        },
        responsive: {
          rules: [{
            condition: {
                maxWidth: 768
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                width: undefined
              }
            }
          }]
        },
        accessibility: {
          description: 'In de eerste grafiek ziet u het aantal aardbevingen in Groningen van 2007 tot nu. In de tweede grafiek ziet u de maximale aardbevingsdichtheid in Groningen van 2007 tot nu. In beide grafieken ziet u of deze parameters in het groene gebied (lage activiteit), het gele gebied (bodengemiddelde activiteit) of het rode gebied (hoge activiteit) vallen'
        }
      }
    };
  }

  getActualValue(data) {
    return data[data.length - 1];
  }

  getActualColor(data) {
    let actual_value = this.getActualValue(data);

    if (actual_value < 30) {
      return '#CA005D';
    } else if (actual_value >= 30 && actual_value < 40) {
      return '#F092CD';
    } else {
      return '#d52b1e';
    }
  }

  render() {
    return (
      <div className="trend-parameters__wrapper">
        <HighchartsReact highcharts={Highcharts} options={this.state.options}/>

        <div className="trend-parameters__actual-wrapper">
          <div className="trend-parameters__actual-card">
            <span className="trend-parameters__actual-title">Actuele waarde</span>
            <span className="trend-parameters__actual-text" style={{ color: this.getActualColor(this.props.series)}}>{this.getActualValue(this.props.series)}</span>
            <span className="trend-parameters__actual-meta">n/jaar</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ChartTrendParametersTotal;
