import React, { Component } from 'react';
import ContentHeader from "../partials/content-header/ContentHeader";
import Menu from '../partials/menu/Menu';

class Home extends Component {
  render() {
    return <div className="page-home content">
      <ContentHeader>
        <h1>Cijfers en feiten over de Staat van Groningen</h1>
        <p>
          In dit dashboard vindt u een overzicht van cijfers en feiten over de voortgang van de schadeafhandeling en versterking van gebouwen als gevolg van de gaswinning in Groningen. Dit dashboard wordt de komende periode steeds verder aangevuld met informatie over de resultaten effecten van <a href={`https://www.nationaalcoordinatorgroningen.nl/versterken/documenten/publicaties/2023/04/25/kabinetsreactie-op-rapport-parlementaire-enquetecommissie`} target={`_blank`}>de maatregelen die het kabinet heeft genomen</a> naar aanleiding van de <a href={`https://rapportaardgaswinning.tweedekamer.nl/conclusies-en-aanbevelingen`} target={`_blank`}>parlementaire enquête</a> naar de gaswinning in Groningen.
        </p>
      </ContentHeader>

      <div className="pagebreak"></div>

      <div className="content__block">
        <h2>Menu</h2>
        <Menu/>
      </div>
    </div>
    ;
  }
}

export default Home;
