import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Page from "./navigation/Page";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import Footer from "./components/partials/footer/Footer";
import Header from "./components/partials/header/Header";
import Highcharts from "highcharts";
import highchartsAccessibility from 'highcharts/modules/accessibility';

highchartsAccessibility(Highcharts);

const App = ({ routes }) => (
  <Router basename={process.env.PUBLIC_URL}>
    <ScrollToTop>
      <Switch>
        {routes.map(route => (
          <Route key={route.path} path={route.path}>
            <Header />
            <Page route={route} />
            { !route.hide_footer && <Footer /> }
          </Route>
        ))}
      </Switch>
    </ScrollToTop>
  </Router>
);

export default App;
